import axios from "axios";

async function kits() {
    const response = await axios.get(process.env.GATSBY_CMS_HOST + "/kits?_sort=Name:ASC")
    .then(response => {
        return response;
    }).catch(error => {
        return false;
    })

    return response;
}

async function updateMany(data) {
    console.dir(data);
    data.Kits.forEach((kitId) => {
        let updateData = {};
        if (data.In) {
            updateData = {...updateData, "Lastin": new Date().toLocaleString("en-US", {timeZone: "Australia/Brisbane"})};
        } else {
            updateData = {...updateData, "Lastout": new Date().toLocaleString("en-US", {timeZone: "Australia/Brisbane"})};
        }
        if (typeof data.Comments === 'string' || data.Comments instanceof String) {
            updateData = {...updateData, "Lastcomment": data.Comments};
            updateData = {...updateData, "Lastcommentby": data.Guide};
        }
        updateData = {...updateData, "Lastholder": data.Guide};
        console.dir(updateData);
        update(updateData, kitId);
    })
}

async function update(data, id) {
    const response = await axios.put(`${process.env.GATSBY_CMS_HOST}/kits/${id}`, data)
    .then(response => {
        return response;
    }).catch(error => {
        return false;
    })

    return response;
}

export const kitsService = {
    kits,
    updateMany
};