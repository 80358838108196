import React, { useState, useEffect }  from "react";
import { Helmet } from "react-helmet";

import Spinner from "../components/Spinner";
import Header from "../components/Header";
import Master from "../layouts/master";

import { kitsService } from "../services/kits";

const Kits = () => {
    const [kits, setKits] = useState(null);

    async function getKitsData() {
        const res = await kitsService.kits();
        setKits(res.data);
    }

    useEffect(() => { getKitsData() }, []);

    function getIn(dateIn, dateOut) {
        // Returns null if unknown
        if(!dateIn) {
            if(!dateOut) {
                return null;
            }

            return false;
        }
        if(!dateOut) {
            return true;
        }

        return Date.parse(dateIn) > Date.parse(dateOut);
    }

    if(!kits) {
        return(
            <Master>
                <Helmet title="Kitter | Kit List" />
                <Header />
                <Spinner />
            </Master>
        );
    } else {
        return(
            <Master>
                <Helmet title="Kitter | Kit List" />
                <Header />
                <h1>Kit List</h1>
    
                <table>
                    <tr>
                        <th style={{width: '10em', minWidth: '10em'}}>Name</th>
                        <th>In?</th>
                        <th style={{width: '9em', minWidth: '9em'}}>Last in</th>
                        <th style={{width: '9em', minWidth: '9em'}}>Last out</th>
                        <th style={{width: '2.5em', minWidth: '2.5em'}}>Last holder</th>
                        <th style={{width: '15em', minWidth: '15em'}}>Last comment</th>
                    </tr>
                    {
                        kits.map((kit) => {
                            return(
                                <tr>
                                    <td>{ kit.Name }</td>
                                    <td style={{backgroundColor: getIn(kit.Lastin, kit.Lastout ) == null ? 'white' : (getIn(kit.Lastin, kit.Lastout) ? 'green' : 'red') }}>
                                        { getIn(kit.Lastin, kit.Lastout) == null ? "" : (getIn(kit.Lastin, kit.Lastout) ? "In" : "Out") }
                                    </td>
                                    <td>{ kit.Lastin == null ? "" : new Date(kit.Lastin).toISOString().replace(/T/, " ").replace(/\..+/, "") }</td>
                                    <td>{ kit.Lastout == null ? "" : new Date(kit.Lastout).toISOString().replace(/T/, " ").replace(/\..+/, "") }</td>
                                    <td>{ kit.Lastholder }</td>
                                    <td>{ kit.Lastcomment == null ? "" : `${kit.Lastcomment} (by: ${kit.Lastcommentby})` }</td>
                                </tr>
                            )
                        })
                    }
                </table>
            </Master>
        );
    }
}

export default Kits;
